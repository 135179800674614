.overlay {

    visibility: hidden
    //border: 3px solid blue;
    display:block
    position: fixed
    top: 0 
    left: 0
    bottom: 0 
    right: 0

    z-index: 9999;
    opacity: 0
    animation: all 0.3s

    background-color: rgba( 255, 255, 255, 0.75);

    &:target {
        visibility: visible
        //border-color: red
        opacity 1
    }

    .image {
        top: 50%;
        position: fixed;
        left: 50%;
        transform: translateX( -50%) translateY( -50%);
    }

    .cancel {
        display:block
        width: 100%;
        height: 100%;
    }
}