/*
---
name: .element-layout
category: LernBuch/Chapter
tag:
 - lernbuch
 - chapter
compile: true
---

## A chapter element for the lernbuch

`.element-layout` centers the element in the page according to `readable-text-width` of the element

```jade
div.aigis-debug.element-layout Some content
```

## Variants
`.-full` removes margin and spans the element to full size of the browser viewport

```jade
div.aigis-debug.element-layout.-full Some content
```

`.-left` floates the element to the left and adds some margin on the right

```jade
div.aigis-debug.element-layout.-left Some content
```

`.-right` floates the element to the right and adds some margin on the left

```jade
div.aigis-debug.element-layout.-right Some content
```

*/
.element-layout {

	conf = theme.components.element-layout;

	margin-right: auto;
	margin-left: auto;

	padding: 0 theme.components.padding-box.medium;

	&.-read {
		max-width: conf.width.read;
	}

	&.-extended {
		max-width: conf.width.extended;
	}

	&.-full {
		max-width: none;
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
}