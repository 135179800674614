/*!
---
name: .drop-down
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This dropdown has the simple mechanism to open and close a dropdown content.

Trigger with mouseover by adding `.-hover` variant

Trigger with js by adding `.-open` variant 

Closed Dropdown
```jade
div.drop-down
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Variants

Variants `.-top`, `.-bottom`, `.-left` and `.-right` can be combined

Bottom left
```jade
div.drop-down.-hover.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Bottom right
```jade
div.drop-down.-hover.-bottom.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right
```jade
div.drop-down.-hover.-top.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top left
```jade
div.drop-down.-hover.-top.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Aside

There is an `.-aside` variant to open content aside

Top left aside
```jade
div.drop-down.-hover.-top.-left.-aside(style="left:200px;width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right aside
```jade
div.drop-down.-hover.-top.-right.-aside(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Relative content

There is a variant for opening the content relative instead of absolute

Relative
```jade
div.drop-down.-hover.-relative(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

JS Trigger
```jade
div.drop-down.-open.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
*/
.drop-down {

	position: relative;

	&.-open > .content {
		display: block;
	}

	&.-hover:hover > .content{
		display: block;
	}

	&.-top > .content {
		bottom: 100%;
	}

	&.-bottom > .content {
		top: 100%;
	}

	&.-left > .content {
		left: 0;
	}

	&.-top.-left.-aside > .content {
		transform: translate( -100%, 100% );
	}

	&.-right > .content {
		right: 0;
	}

	&.-top.-right.-aside > .content {
		transform: translate( 100%, 100% );
	}

	&.-relative{
		> .content {
			position: relative;
		}
	}

	> .toggle {
		display: inline-block;
	}

	> .content {
		position: absolute;
		z-index: 1000;
		display: none;
	}
}