.language-selection {
    position: absolute
    top: rem(-14px);
    left: rem(-14px);
    background-color: white;
    padding: rem(14px);
    box-shadow: inset -1px 0 0 0 #D5D5D7, inset 1px 0 0 0 #D5D5D7, inset 0 -1px 0 0 #D5D5D7, inset 0 1px 0 0 #D5D5D7, 0 2px 4px 0 rgba(213,213,215,0.5);

    &.-hide {
        display:none
    }

    a {
        color: theme.colors.text

        &.-active, &:hover {
            color: theme.colors.action
        }
    }
}

.current-locale {
    cursor:pointer;
}