.lernbuch-card {
    width: rem( 233px );
    height: rem( 340px );

    display: inline-block
    overflow:hidden

    h4 {
        font-size: rem( 18px )
        line-height: rem( 24px )
        font-weight: bold
    }

    &:hover {
        cursor pointer
        box-shadow: 0px 10px 10px rgba( 0,0,0,0.18)

        h4, a {
            color: theme.colors.action
        }
    }


}