.copy-link {
    display: inline-block;
    vertical-align: top;
    margin-left: rem(8px);

    .icon { 
         width: rem(25px); 
         height: rem(25px);
         fill: theme.colors.line;
    }

	&:hover .icon {
		fill: theme.colors.text;
	}
}
