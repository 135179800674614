.page-footer {
    margin:0 auto 0 auto;
    width: 98%;
    position:relative;
    .container {
            position:absolute;
            right: 0;
            bottom: 0;
            left: 0; 

        &::before {
            content: " ";
            width: 100%;
            background: #feed01;
            height: 2px;
            transform: rotate(-2deg);
            position: absolute;
            top: -40px;
        }

        .sponsors {
            margin:30px 0 0 0;
        }

        .logos {
            display:inline;
            
            &.-right {
                .logo {
                    float:right;
                }
            }
            
            .logo {
                height: auto;
                vertical-align: middle;
                margin-right: 30px;

            }
            
            .sbb {
                max-width: 210px;
            }
            .bls {
                max-width: 110px;
            }
            .rhb {
                max-width: 140px;
            }
            .voev {
                max-width: 140px;
            }
            .login {
                width: 210px;
                margin-right:0;
                transform: translateY(-50%);
            }
        }
    }

}  


@media only screen and (max-width: theme.settings.breakpoints.large) {
    .page-footer {
        margin-top: 600px;

        .logos {
            
            &.-left {
                .logo {
                    display:block;
                    margin-bottom: 10px;
                }
            }
            &.-right {
                .login {
                    transform:none;
                    margin-bottom: 20px;
                }
            }
        }
    }

}

@media only screen and (max-width: theme.settings.breakpoints.small) {
    .page-footer {
        .container {
            margin-top: 500px;
            .sponsors {
                margin:0;
                text-align:center;
                .logos {
                    .logo {
                        margin:10px auto;
                    }
                    &.-right {
                        .logo {
                            float:none;
                        }
                    }
                }
            }
        }
    }

}