.toc-level {

    cursor:pointer;
    line-height: 1.35em

    &:hover {
        color: theme.colors.action
    }

    &.-h1 {
        font-weight: bold
        font-size: rem( 24px )
    }

    &.-h2 {
        font-weight: bold
        font-size: rem( 21px )
        margin-left: rem( 30px )
    }

    &.-h3 {
        //font-weight: bold
        font-size: rem( 21px )
        margin-left: rem( 60px ) 
    }
}