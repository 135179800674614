.checkbox-label {
    position relative
    display block
    padding-left: rem( 20px )

    input {
        position absolute
        top: rem( 5px )
        left: 0
    }
}