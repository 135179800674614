.edit-button {

    width: rem( 32px )
    height: rem( 32px )

    a{
        position: relative
        left: rem( 3px )
        top: rem( 4px )
    }
}