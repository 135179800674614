/*!
---
name: .border-box
category: Box/Border
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box {

	conf = theme.components.border-box;

	border-width: conf.width;
	border-color: conf.color;	
	
	for name in 'left' 'right' 'top' 'bottom' {
		&.-{name} {
			border-{name}-style: solid;
		}
	}

	&.-all {
		border-style: solid;	
	}
}