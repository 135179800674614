.term-search {
    position: absolute
    top: 0
    left 0
    width: rem( 250px )
    height: rem( 56px )
    padding-left: rem( 20px )

    input {
        font-size: rem( 15px )
        border: 0
    }

    .flex-layout {
        height: 100%
    }

}